module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en-US","ja-JP","fr-FR","de-DE","es-MX"],"defaultLanguage":"en-US","redirect":false,"siteUrl":"https://www.8thwall.com","i18nextOptions":{"fallbackLng":"en-US","supportedLngs":["en-US","ja-JP","fr-FR","de-DE","es-MX"],"ns":[],"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
